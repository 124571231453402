// Typekit id duh3eow
//Fonts
$caslon : "adobe-caslon-pro",serif;
$proxima : "proxima-nova",sans-serif;
//$garamond : "adobe-garamond-pro",serif;
$garamond: "garamond-premier-pro",serif;

$black: #1d1d1d;
$brown : #a58e6c;
$sand: #d7d2cd;
$grey: #838282;
$mediumgrey: #cccccc;
$lightgrey: #eeeeee;

$greysection: #f2f2f2;
$iconbackground: #b4ada5;


// Imports
@import 'mixins';

html, body {
	height: 100%;
}

button {
  outline: none;
  outline-style: none;
}

// Basics
body {
	overflow-x: hidden;
	color: black;
	font-family: $garamond;
    font-style: normal;
    font-size: 22px;
    font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,h2,h3,h4,h5 {
  font-family: $caslon;
  font-size: 54px;
  font-weight: 600;
  color: black;
  letter-spacing: 2px;
  margin: 0 0 30px 0;
  padding: 0;
  line-height: normal;
  text-transform: uppercase;
  @media (max-width: 992px) {
    font-size: 44px;
  }

    @media (max-width: 768px) {
        font-size: 32px;
    }
}
h2 {
  font-size: 42px;
  @media (max-width: 992px) {
    font-size: 32px;
  }

    @media (max-width: 768px) {
        font-size: 24px;
    }
}
h3 {
  font-size: 34px;
  @media (max-width: 992px) {
    font-size: 24px;
  }

    @media (max-width: 768px) {
        font-size: 21px;  
    }
}
h4, h5, h6 {
  font-size:19px;
}

p {
  margin: 0 0 30px 0;
  &:last-of-type {
    margin-bottom: 0;
  }
}

a {
  color: $brown;
  text-decoration: underline;

  &:hover {
    color: $brown;
    text-decoration: none;
  }
}
.text-center {
  text-align: center;
}
// Button
.button {
  display: inline-block;
  width: auto;
  min-width: 270px;
  height: auto;
  font-size: 17px;
  line-height: 34px;
  text-transform: uppercase;
  font-family: $proxima;
  letter-spacing: 2px;
  text-decoration: none;
  text-align: center;
  outline: none;
  border: 0;
  padding: 18px 30px;
  background: white;
  color: black;
  margin-right: 10px;
  @include transition(all, 200ms);

  @media (max-width: 600px) {
    padding:7px 14px;
    font-size: 12px;
    min-width: inherit;
    margin: 0 auto 10px auto;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &.brown {
    color: $brown;
  }

  &:hover {
    background: black;
    color: white;
    text-decoration: none;
  }

  &.dark {
    background: black;
    color: white;

    &:hover {
      background: white;
      color:black;
    }
  }
  &.outline-white {
    background: transparent;
    color: white;
    border: 1px solid white;
    
    &:hover {
      background: black;
    }
  }
  &.outline-brown {
    background: transparent;
    color: $brown;
    border: 1px solid $brown;

    &:hover {
      background: white;
    }
  }
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid white;
}

section{
  position: relative;
  width:100%;
  padding: 80px 0;
}
section.monuments,
.col-monuments{
  .gallery {
    overflow: hidden;

    a {
      position: relative;
      display: block;
      float: left;
      width: 31.66%;
      margin: 0 2.5% 2.5% 0;

      @media (max-width: 992px) {
        width: 48.25%;
      }
      @media (max-width: 600px) {
        width: 100%;
        margin-right: 0;
      }

      &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.55);
        z-index: 0;
      }
      @media (min-width: 992px) {
        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }
      @media (max-width: 992px) {
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
      img {
        position: relative;
        max-width: 100%;
        @media (max-width: 600px) {
          width: 100%;
          max-width: inherit;
        }
      }
      .text {
        position: absolute;
        z-index: 1;
        top: 14px;
        left: 14px;
        width: calc(100% - 28px);
        height: calc(100% - 28px);
        text-align: center;
        @include transition(all, 200ms);

        .vertical-center {
          position: absolute;
          top: 50%;
          width: 100%;
          transform: translateY(-50%);
          padding: 0;
          transition: all 1s;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }

        h4 {
          color: white;
          margin-bottom: 0;
          font-weight: 400;
        }
        hr {
          margin: 15px 14px 30px 14px;
          border-top: 1px solid $mediumgrey;
          display: none;
          @include transition(all, 200ms);
        }
        button {
          margin: 0 auto;
          font-size: 15px;
          padding: 10px 25px 8px;
          min-width: inherit;
          display: none;
          @include transition(all, 200ms);
        }
        span {
          display: none;
          font-size: 18px;
          margin-top: 10px;
          @include transition(all, 200ms);
        }
      }
      &:hover {

        @media (min-width: 992px) {
          .text {
            background: white url('../img/overlay.jpg') top right no-repeat;
            background-size: cover;
            box-shadow: 0 3px 59px rgba(0, 0, 0, 0.15);

            h4 {
              color: black;
            }
            hr {
              display: block;
              -webkit-animation: fadeIn 250ms ease-in-out both;
              -o-animation: fadeIn 250ms ease-in-out both;
              animation: fadeIn 250ms ease-in-out both;
            }
            button, span {
              display: block;
              -webkit-animation: fadeIn 250ms ease-in-out both;
              -o-animation: fadeIn 250ms ease-in-out both;
              animation: fadeIn 250ms ease-in-out both;
            }
          }
        }
      }
    }
  }
}
.landingpage:not(.search-page) {
  section:not(.monuments) {
    .gallery {
      a {
        display: block;
        margin-bottom: 20px;
        pointer-events: none;
        cursor: default;

        div.text {
          display: none;
        }
      }
    }
  }
}
@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

// Navigation Section
.navigation-section {
  width: 100%;
  height: auto;
  background-color: white;
  padding-top: 40px;
  padding-bottom: 40px;
  position: fixed;
  z-index: 99;
  top: 0;
  transition: all .3s;
  border-bottom: 1px solid #cccccc;

  @media (max-width: 767px) {
    padding: 15px 0;
  }

  @media (max-width: 1300px) {
    .container {
      width: 100%;
    }
  }

  .top-navigation {
    position: absolute;
    top: -20px;
    right: 15px;

    .tel {
      position: relative;
      display: inline-block;
      background: url('../img/tel.svg') 0 5px no-repeat;
      background-size: 22px 22px;
      padding-left: 30px;
      text-decoration: none;

      @media (max-width: 998px){
        position: absolute;
        top: 17px;
        right: 100px;
        text-indent: -9999px;
        background-size: 26px 26px;
      }
    }
  }

  // Navicon
  .navicon {
    float: right;
    width: 35px;
    height: 25px;
    background-image: url('../img/navicon.svg');
    background-position: center center;
    background-size: 35px 25px;
    background-repeat: no-repeat;
    margin: 0;
    display: block;

    @media (min-width:992px) {
      display: none;
    }
  }
  .logo-wrapper {
    display: inline-block;
  }
  // Logo
  .logo {
    width: auto;
    height: 20px;
    @include transition(all, 300ms);

    @media (min-width:768px) {
      //width: 286px;
      width: auto;
      height:35px;
    }
  }

  // Navigation
  ul.navigation {
    float: none;
    position: relative;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: none;
    @media (min-width:992px) {
      display: block;
      position: absolute;
      right: 15px;
    }

    li {
      display: block;
      float: none;
      margin-top: 15px;
      @media (min-width:992px) {
        float: left;
        margin-top: 0;
      }

      &:last-child {
        a {
          padding-right: 0;
        }
      }

      a {
        display: block;
        padding: 0 13px;
        font-family: $caslon;
        font-size: 16px;
        line-height: 18px;
        color: black;
        text-transform: uppercase;
        text-decoration: none;
        @include transition(all, 300ms);

        &:hover {
          color: $brown;
          text-decoration: none;
        }

        &.active {
          color: $brown;
          text-decoration: none;
        }
      }
    }
  }

  &.scrolled {
    padding: 25px 0;

    @media (max-width: 998px) {
      padding: 15px 0;
    }
    .logo {
      width: auto;
      height: 20px;
    }

    .top-navigation {
      @media (min-width: 999px) {
        top: -15px;
      }
    }

    ul.navigation {
      margin-top: -5px;

      li {

        a {
          font-size: 14px;
        }
      }
    }
  }
}


// Header Section
.header-container.dashboard-header {
  height: 592px;
  max-height: 75%;
  margin-top: 114px;

  @media (max-width: 767px) {
    margin-top: 62px;
  }

  .header-content {
    text-align: left;

    .align-middle {
      margin-right: auto;
      margin-left: auto;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;

      @media (min-width: 768px) {
        width: 750px;
      }
      @media (min-width: 992px) {
        width: 970px;
      }
      @media (min-width: 1200px) {
        width: 1170px;
      }

      h3 {
        text-shadow: 2px 3px 21px rgba(0, 0, 0, 0.99);
        color: #ffffff;
        font-family: $proxima;
        font-size: 23px;
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 25px;
        display: block;
        max-width: 67%;
        @media (max-width: 992px) {
          margin-bottom: 10px;
          font-size: 23px;
        }
      }
      h1 {
        text-shadow: 2px 3px 21px rgba(0, 0, 0, 0.99);
        color: #ffffff;
        font-family: $caslon;
        font-size: 54px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 30px;
        display: inline-block;
        max-width: 67%;
        border-bottom:1px solid white;
        @media (max-width: 992px) {
          margin-bottom: 10px;
          font-size: 34px;
          max-width: inherit;
        }
      }
      p {
        display: block;
        max-width: 67%;
        font-size: 28px;
        line-height:38px;
        @media (max-width: 992px) {
          margin-bottom: 10px;
          font-size: 24px;
          max-width: inherit;
        }
      }
      .button {

      }
    }
  }
  @media (max-width: 600px) {
    height: 500px;
    max-height: inherit;
  }
  @media (min-width: 768px) {
    height: 592px;
    max-height: inherit;
  }
  @media (min-width: 992px) {
    height: 480px;
    max-height: inherit;
  }
  @media (min-width: 1200px) {
    height: 592px;
    max-height: 75%;
  }
}

// Monuments Section
.monuments {

  h2 {
    line-height: 50px;
    margin-bottom: 0;
  }

  h3 {
    color: #838282;
    font-family: $proxima;
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
    text-transform: uppercase;
    padding-bottom: 25px;
    border-bottom: 1px solid $mediumgrey;
  }
}


// Appointment Section
.appointment {
  background: $black url('../img/appointment.png') top right no-repeat;
  background-size: contain;
  
  h3 {
    color: white;
    margin-bottom: 0;
    @media (max-width:600px) {
      text-align: center !important;
    }
  }
  p {
    color: #838282;
    font-family: $proxima;
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
    text-transform: uppercase;
    letter-spacing: 2px;
    @media (max-width:600px) {
      text-align: center !important;
    }
  }
  .button {

    @media (max-width:600px) {
      margin-top: 20px;
    }
  }
}

// Why Section
.why {
  padding-top: 0;
  .bg-grey {
    background: $greysection url('../img/bloem.png') bottom center no-repeat;
    padding: 100px 100px 20px 100px;

    img {
      width: 100%;
    }

    @media (max-width:768px) {
      padding: 20px;
      padding-top: 80px;
    }

    h2 {
      font-size: 36px;

      @media (max-width: 768px) {
        font-size: 27px;
        line-height: 34px;
      }
    }
    .grid {
      margin-bottom: 80px;

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
    .grid:nth-child(odd) {
      padding-right: 50px;
      @media (max-width: 992px) {
        padding-right: 15px;
      }
    }
    .grid:nth-child(even) {
      padding-left: 50px;
      @media (max-width: 992px) {
        padding-left: 15px;
      }
    }
    .image {
      display: block;
      border: 6px solid #cccccc;
      img {
        width: 100%;
      }
    }
    .text {
      p {
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        margin-top: 15px;
        font-family: $caslon;
      }
    }
  }
}

// Statue Section
.statue {
  padding: 0 0 70px 0;

  img {
    max-width: 100%;
  }
}

// About Section
.about {
  background: $greysection url('../img/bg2.png') bottom right no-repeat;
  padding: 140px 0 180px 0;

  @media (max-width:1280px) {
    padding: 80px 0;
  }

  @media (max-width:600px) {
    padding: 80px 0;
  }

  @media (min-width: 1200px) and (max-width: 1300px) {
    .col-md-offset-3 {
      margin-left: 29%!important;
    }
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width: 25%;
    background-position: center center;
    -webkit-background-size:cover;
    background-size:cover;

    @media (max-width: 992px) {
      position: relative;
      width: 100%;
      height: 300px;
      margin-bottom: 30px;
    }
    @media (min-width: 1200px) {
      width: 25%;
    }
    @media (min-width: 1600px) {
      width: 30%;
    }
  }
  h2 {
    margin-bottom: 0;
  }
  h3 {
    color: #838282;
    font-family: $proxima;
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
    text-transform: uppercase;
    border-bottom:1px solid #cccccc;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 90px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

// Slider Section
.slider {

  h2 {
    margin-bottom: 0;
  }
  h3 {
    color: #838282;
    font-family: $proxima;
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
    text-transform: uppercase;
    border-bottom:1px solid #cccccc;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  .slick-next,.slick-prev {
    position: absolute;
    top:50%;
    right: -10px;
    margin-top: -41px;
    width: 83px;
    height: 83px;
    box-shadow: 0 3px 59px rgba(0, 0, 0, 0.35);
    background: #ffffff url('../img/icon_arrow-right.svg') center center no-repeat;
    background-size: 19px 36px;
    border:none;
    @include transition(all, 200ms);
    &.slick-disabled {
      opacity: 0;
    }

    @media (max-width: 768px) {
      opacity:0.8;
    }
    &:hover {
      background-color: $lightgrey;
      right: -20px;
    }

    &.slick-prev {
      left: -10px;
      z-index: 2;
      background: #ffffff url('../img/icon_arrow-left.svg') center center no-repeat;
      background-size: 19px 36px!important;

      &.slick-disabled {
        opacity: 0;
      }
    }
  }
  .slick-slider .slick-list {
    padding: 0 50px 0 0 !important;
    @media(max-width: 480px) {
      padding: 0 !important;
    }
  }
  .slideshow {
    margin-top: 20px;
    .slick-slide {
      position: relative;
      margin-right: 30px;
      @media(max-width: 480px) {
        margin-right: 0;
      }

      img {
        width: 100%;
      }
      span {
        display: block;
        position: absolute;
        content:'';
        top:4%;
        left:3%;
        border:1px solid white;
        width: 94%;
        height:92%;
      }
    }
  }
}

// Measurements Section
.measurements {
  background-color: $greysection;

  h2 {
    margin-bottom: 0;
  }
  h3 {
    color: #838282;
    font-family: $proxima;
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
    text-transform: uppercase;
    border-bottom:1px solid #cccccc;
    padding-bottom: 30px;
    margin-bottom: 30px;
    display: inline-block;
    letter-spacing: 2.3px;
  }
  img {
    @media (max-width: 768px) {
      margin: 0 auto;
      display: block;
    }
  }
}


// Contact Section
.footer-section {
  padding:140px 0;
  background: transparent url('../img/map.jpg') center center no-repeat;
  background-size: cover;

  .logo {
    display: block;
    margin-top: 90px;
    max-width:100%;
  }
  // Facebook icon
  .fb-icon {
    width: 35px;
    height: 35px;
    background: #b4ada5 url('../img/logo_fb.svg') center center no-repeat;
    background-size: 25px 25px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all,200ms;
    -moz-transition: all,200ms;
    -ms-transition: all,200ms;
    -o-transition: all,200ms;
    transition: all,200ms;
    display: inline-block;

    &:hover {
      background-color: #a58e6c;
    }
  }

  @media (max-width: 768px) {
    padding:70px 0;
  }

  &:before {
    position: absolute;
    top:0;
    right:0;
    content: '';
    width: 100%;
    height:100%;
    background: transparent url('../img/triangle.svg') right bottom no-repeat;
    background-position: 100%;
    z-index:0;
  }
  &:after {
    position: absolute;
    top:0;
    right:0;
    content: '';
    width: 100%;
    height:100%;
    background: transparent url('../img/bg-contact2.png') right bottom no-repeat;
    background-position: 100%;
    z-index:0;
  }

  > .container {
    position: relative;
    z-index:1;
    box-shadow: 0 3px 35px rgba(0, 0, 0, 0.35);
    background-color: #ffffff;
    padding: 0;
  }
  .contact-top {
    padding: 75px;

    @media (max-width: 768px) {
      padding:50px;

      .col-md-6:last-child {
        margin-top: 30px;
      }
    }

    h2 {
      margin-bottom: 0;
    }
    h3 {
      color: #838282;
      font-family: $proxima;
      font-size: 18px;
      font-weight: 300;
      line-height: 36px;
      text-transform: uppercase;
      border-bottom:1px solid #cccccc;
      padding-bottom: 30px;
      margin-bottom: 50px;
      letter-spacing: 2.3px;
    }
    p {
      font-size: 21px;
    }
    a {
      font-size:21px;
      color: black;
    }
    ul {
      margin: 0;
      padding:0;
      list-style: none;

      li {
        max-width: 200px;

        span {
          display: inline-block;
          float: right;
        }
      }
      &.social {
        margin: 40px 0 0 0;
        li {
          position: relative;
          padding-left: 50px;
          margin-bottom: 10px;
          max-width: inherit;

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 35px;
            height: 35px;
            content: '';
            background: $iconbackground url('../img/icon_phone.svg') center center no-repeat;
            background-size: cover;
            background-size: 15px 15px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            @include transition(all, 200ms);

          }
          &.mail {
            &:before {
              background: $iconbackground url('../img/icon_email.svg') center center no-repeat;
              background-size: 17px 10px;
            }
          }
          &.facebook {
            &:before {
              background: $iconbackground url('../img/logo_fb.svg') center center no-repeat;
              background-size: 25px;
            }
          }

          a {
            color: black;
            font-size: 21px;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          &:hover {
            &:before {
              background-color: $brown;
            }
          }
        }
      }
    }
  }
  .contact-bottom {
    background-color: $greysection;
    padding: 75px;

    @media (max-width: 768px) {
      padding:20px;
    }
  }
}

// Contactform
#contactform {

  .sitekick input[type=text], .sitekick input[type=date], .sitekick input[type=url], .sitekick input[type=email], .sitekick input[type=password], .sitekick select, .sitekick textarea,
  input, textarea {
    width: 100%;
    height: auto;
    padding: 16px 30px 12px 30px;
    border: 1px solid rgba(156, 149, 143, 0.23);
    background-color: #ffffff;
    color: #000000;
    font-family: $caslon;
    font-size: 21px;
    font-style: italic;
    line-height: normal;
    margin-bottom: 20px;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    border-radius:0;
    &.form-error {
      border:1px solid red;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
    }

    @media (max-width: 767px) {
      padding: 16px 20px 12px 20px;
    }
  }
  textarea {
    min-height: 155px;
    resize: none;
  }
  button {
    max-width: 178px;
    min-width: inherit;
  }
}


// Monuments pages
.submenu {
  padding-top: 154px;
  padding-bottom: 0;

  ul {
    margin: 0;
    padding:0;
    list-style: none;

    li {
      display: block;
      float: left;
      margin-bottom: 10px;

      a {

        &.button {
          font-size: 13px;
          min-width: inherit;
          border: 1px solid black;
          line-height: normal;
          padding:20px 25px;
          margin-right: 10px;

          @media (max-width: 767px) {
            padding: 10px 13px;
          }
        }
      }
      &:last-of-type {
        .button {
          margin-right: 0;
        }
      }
      &.active {
        .button {
          background-color: black;
          color: white;
        }
      }
    }
  }
}
.monument {

  h1 {
    font-size:42px;
    margin-bottom: 0;

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }
  h2 {
    color: #838282;
    font-family: $proxima;
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
    text-transform: uppercase;
    border-bottom:1px solid #cccccc;
    padding-bottom: 30px;
    margin-bottom: 30px;
    letter-spacing: 2.3px;
  }

  .gallery {
    padding-top: 0;

    a {
      &:after {
        background: transparent;
      }
    }
    .magnify {
      width: 109px;
      height: 109px;
      border: 1px solid #a58e6c;
      background: transparent;
      img {
        width: 34px;
        height: 34px;
      }
    }
  }

  @media (max-width: 767px) {
    section {
      padding: 40px 0px;
    }
  }
}
.no-home + div {
  margin-top: 154px;
}
// Sitekick specific styling
.sitekick {
  .navigation-section {
    top:70px;
  }
  #cropper {
    h1 {
      font-size: 36px;
      text-transform: none;
    }
    h2 {
      font-size: 30px;
      text-transform: none;
    }
  }
}
.sitekick input[type="text"], .sitekick input[type="date"], .sitekick input[type="url"], .sitekick input[type="email"], .sitekick input[type="password"], .sitekick select, .sitekick textarea {
  width: 100%;
}


// Header grey bar section
.header-grey-bar {
  padding: 30px 0px;
  background-color: #dddddd;
  font-family: $caslon;
  padding-bottom: 25px;
}

// Lightcase
#lightcase-overlay {
  background-color: #fff!important;
}

#lightcase-info {
  padding: 15px 30px!important;
  #lightcase-title {
    font-size: 21px!important;
    line-height: 21px!important;
  }
}

#lightcase-nav a[class*='lightcase-icon-'] {
  width: 100px!important;
  height: 100px!important;
}

.locations {
  display: none;
  h2 {
    font-family: $proxima;
    font-weight:300;
    font-size: 24px;
    text-transform: none;
    margin-bottom: 5px;
    color: $brown;
  }
  p {
    font-size: 22px;
  }
}
.sitekick .locations {
  display: block;
}

@media (max-width: 767px) {
  .col-monuments {
    .gallery {
      a {
        position: relative;
        &:after {
          content: "";
          bottom: 0px;
          right: 0px;
          top: auto;
          left: auto;
          width: 40px;
          height: 40px;
          background-color: #FFF;
          position: absolute;
          background-image: url('../img/icon_search.svg');
          background-size: 60% 67%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }
}

// Video section
.video {
    padding-top: 0;

    .video-title {
        font-size: 30px;
        border-bottom: 1px solid #cccccc;
    }

    .video-item {
        position: relative;
        cursor: pointer;
        margin-bottom: 30px;

        img {
            display: block;
            width: 100%;
        }

        .video-overlay {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height:100%;
            padding: 14px;
            display: block;

            .video-overlay-border {
                position: relative;
                width: 100%;
                height: 100%;
                display: block;
                text-align: center;
                border: 1px solid white;

                img {
                    position: relative;
                    top:50%;
                    width: 80px;
                    margin: -40px auto 0 auto;
                    @include transition(all, 300ms);
                    -webkit-transform: scale(0.9);
                    -moz-transform: scale(0.9);
                    -ms-transform: scale(1);
                    -o-transform: scale(0.9);
                    transform: scale(0.9);
                }
            }
        }
        &:hover {
            .video-overlay {
                .video-overlay-border {
                    img {
                        -webkit-transform: scale(1);
                        -moz-transform: scale(1);
                        -ms-transform: scale(1);
                        -o-transform: scale(1);
                        transform: scale(1);
                    }
                }
            }
        }
    }
    .video-popup {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height:100%;
        z-index:99999;
        display: none;
        background-color: rgba(255,255,255,0.8);
        text-align: center;

        .video-close {
            position: absolute;
            top: 30px;
            right: 30px;
            cursor: pointer;
        }

        .align {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            max-width: 50%;
            margin: 0 auto;

            @media (max-width: 992px) {
                max-width: 75%;
            }
        }

        .videoWrapper {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 25px;
            height: 0;
        }
        .videoWrapper iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
        }

    }

}

.references {

    h2 {
        font-size: 30px;
        border-bottom: 1px solid #cccccc;
    }

  .button.dark {
    border:1px solid black;

    &:hover {
      border:1px solid black;
    }
  }

    .reference-wrapper {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;

        @media (max-width: 992px) {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
        }
        @media (max-width: 567px) {
            -webkit-column-count: 1;
            -moz-column-count: 1;
            column-count: 1;
        }

        .reference-item {
            display: inline-block;
            width: 100%;
            background: #f2f2f2;
            margin-bottom: 30px;

            img {
                width: 100%;
            }

            .reference-item-content {
                padding: 40px;

                h3 {
                    font-size: 18px;
                }
                p {

                }
            }
        }
    }
  .reference-single {
      width: 100%;
      display: table;
      height:100%;

    .reference-image {
        display: table-cell;
        width: 50%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

      @media (max-width: 992px){
        display: block;
        width: 100%;
        min-height: 370px;
      }
    }
    .reference-content {
        display: table-cell;
        width: 50%;
        background: #f2f2f2;
        padding: 55px;

      @media (max-width: 992px){
        display: block;
        width: 100%;
      }

        h3 {
            font-size: 22px;
        }
    }
  }

}

.searchbutton {
  position: relative;
  display: block;
  float: right;
  margin-top: 3px;
  margin-left: 20px;
  cursor: pointer;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNQTFRFU1NT9fX1lJSUXl5e1dXVfn5+c3Nz6urqv7+/tLS0iYmJqampn5+fysrK39/faWlp////Vi4ZywAAABF0Uk5T/////////////////////wAlrZliAAABLklEQVR42rSWWRbDIAhFHeOUtN3/ags1zaA4cHrKZ8JFRHwoXkwTvwGP1Qo0bYObAPwiLmbNAHBWFBZlD9j0JxflDViIObNHG/Do8PRHTJk0TezAhv7qloK0JJEBh+F8+U/hopIELOWfiZUCDOZD1RADOQKA75oq4cvVkcT+OdHnqqpQCITWAjnWVgGQUWz12lJuGwGoaWgBKzRVBcCypgUkOAoWgBX/L0CmxN40u6xwcIJ1cOzWYDffp3axsQOyvdkXiH9FKRFwPRHYZUaXMgPLeiW7QhbDRciyLXJaKheCuLbiVoqx1DVRyH26yb0hsuoOFEPsoz+BVE0MRlZNjGZcRQyHYkmMp2hBTIzdkzCTc/pLqOnBrk7/yZdAOq/q5NPBH1f7x7fGP4C3AAMAQrhzX9zhcGsAAAAASUVORK5CYII=) center center no-repeat;
  background-size: 25px 25px;
  background-position: left top 0;
  padding-top: 5px;
  padding-left: 33px;
  font-family: "adobe-caslon-pro",serif;
  font-size: 16px;
  text-transform: uppercase;
  transition: all .3s;

  @media (max-width: 998px){
    position: absolute;
    top: 17px;
    right: 50px;
    min-width: 30px;
    min-height: 30px;

    span {
      display: none;
    }
  }

  @media (max-width: 380px){
    margin: 0 10px 0 0;
  }
}
.search {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height:100%;
  z-index:99999;
  display: none;
  background-color: rgba(255,255,255,0.8);
  text-align: center;

  .search-close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
  .align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    max-width: 50%;
    margin: 0 auto;

    @media (max-width: 992px) {
      max-width: 90%;
    }
  }
  .searchform {
    input {
      width: 75%;
      float: left;
      height: auto;
      padding: 16px 30px 12px 30px;
      border: 1px solid black;
      background-color: #ffffff;
      color: #000000;
      font-family: $caslon;
      font-size: 21px;
      font-style: italic;
      line-height: normal;
      margin-bottom: 20px;
      -webkit-border-radius:0;
      -moz-border-radius:0;
      border-radius:0;
      outline: none;
    }
    .button.dark {
      width: 25%;
      min-width: inherit;
      float: right;
      padding: 16px 16px 17px 16px;
    }
  }

}

.no-padding-top {
  padding-top: 0!important;
}

body.landingpage:not(.search-page) {

    section:not(.navigation-section) {
        padding: 0 0 50px 0;
    }
    h1, h2, h3, h4, h5 {
        font-family: "adobe-caslon-pro",serif;
        font-size: 40px;
        font-weight: 600;
        line-height: 1.1;
        color: black;
        letter-spacing: 2px;
        margin: 0 0 20px 0;
        padding: 0;
        text-transform: uppercase;
    }
    h2 {
        font-size: 34px;
    }
    h3 {
        font-size: 30px;
    }
    h4 {
        font-size: 28px;
    }
    h5 {
        font-size: 24px;
    }
    h6 {
        font-size: 20px;
    }
    .image-wrapper {
        position: relative;
        display: block;
        width: 100%;
        height:100%;

      @media (max-width: 768px) {
        img {
          width: 100%;
        }
      }
    }

    @media (min-width: 1024px) {
        .row.page-row-blocks {
            display: table;

            [class*="col-"] {
                float: none;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
    @media (max-width: 1024px) {
        [class*="col-"] {
            padding-bottom: 25px;
        }
        .image-wrapper {
            text-align: center;
        }
    }

}



#cookie_tool_small {
  position: fixed;
  width: 100%;
  height: auto !important;
  background: #FFF;
  bottom: 0px !important;
  left: 0px;
  -webkit-box-shadow: 0px -2px 5px 3px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px -2px 5px 3px rgba(0,0,0,0.05);
  box-shadow: 0px -2px 5px 3px rgba(0,0,0,0.05);
  z-index: 9;

  .cookie_tool_inner {
    text-align: center;
    padding: 0px !important;

    .h4 {
      display: none !important;
    }

    p {
      display: inline-block;
      margin-right: 20px !important;

      @media (max-width: 768px) {
        padding-top: 15px;
      }
    }

    form {
      display: inline-block;

      .button {
        width: auto;
        height: auto;
        border: none;
        padding: 10px 20px;
        background-color: #6cb95b;
        -webkit-border-radius: 5px;
        color: #FFF !important;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        text-decoration: none;
        outline: none;
        display: inline-block;
        border-radius: 0 !important;

        &:hover {
          color: #FFF;
          background-color: #519342;
        }
      }

    }

    .cookie_tool_more {
      margin-left: 20px !important;
    }

  }
}

.cookie_tool_submit {
  margin-bottom: 30px !important;
}

#cookie_tool_large .cookie_tool_list .cookie_tool_list_item .cookie_tool_do .h5 {
  color: #000 !important;
}